import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutUs = () => (
  <Layout>
    <Seo title="About Us" />
    <main>
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">会社概要</h1>
                <p className="lead fw-normal text-muted mb-4">
                  弊社についてご案内いたします。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <table className="table align-middle">
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>社名</td>
                    <td>株式会社 ディオイズ (英表記: Dioiz Co., Ltd.)</td>
                  </tr>
                  <tr>
                    <td>所在地</td>
                    <td>
                      20th Floor, 149, Sejong-daero, Jongno-gu, Seoul, Korea
                    </td>
                  </tr>
                  <tr>
                    <td>代表者</td>
                    <td>代表取締役 ソンウォンホ(Mr. Sung Wonho)</td>
                  </tr>
                  <tr>
                    <td>設立</td>
                    <td>2014年</td>
                  </tr>
                  <tr>
                    <td>事業内容</td>
                    <td>組み込み向けソフトウェアの販売・技術サポート</td>
                  </tr>
                  <tr>
                    <td>代表メール</td>
                    <td>dioiz@dioiz.com</td>
                  </tr>
                  <tr>
                    <td>連絡先</td>
                    <td>+82-2-785-5709</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <p className="lead fw-normal text-muted mb-4">アクセス</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container px-5 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center">
                <iframe
                  title="DIOIZ JAPAN"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.384473333043!2d126.97397881525204!3d37.56956333171247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2ecd76e7d6d%3A0xf5538c009106ed25!2zSEog67mE7KaI64uI7Iqk7IS87YSwIOq0ke2ZlOusuA!5e0!3m2!1sja!2sjp!4v1627219646801!5m2!1sja!2sjp"
                  width="100%"
                  height="450"
                  style={{ border: "10" }}
                  allowFullScreen
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section></section>
    </main>
  </Layout>
)

export default AboutUs
